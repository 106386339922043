// https://vuex.vuejs.org/zh-cn/intro.html
// make sure to call Vue.use(Vuex) if using a module system
import Vue from 'vue'
import Vuex from 'vuex'
import dayjs from 'dayjs'

Vue.use(Vuex)
const now = dayjs().valueOf()
// let ua = window.navigator.userAgent.toLowerCase()

const store = new Vuex.Store({
  state: {
    now: now,
    month: null,
    year: null,
    vacation: null,
    plans: null,
    isMiniapp: window.location.href.indexOf('from=') > 0 && window.location.href.indexOf('from=itab') < 0,
    isiTab: window.location.href.indexOf('from=itab') > 0,
    isiOS: window.location.href.indexOf('from=ios') > 0
  },
  mutations: {
    setConfig: (state, config) => {
      const obj = state
      obj.year = config.year
      obj.vacation = config.vacation
      obj.plans = config.plans
    },
    setNow: (state) => {
      const obj = state
      obj.now = dayjs().valueOf()
    },
    setMonth: (state, month) => {
      const obj = state
      obj.month = month
    }
  }
})

export default store
