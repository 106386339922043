<template>
<div class="container">
  <h2 class="page-title">{{ year }} 年假期余额</h2>
  <div class="stats">
    <div class="percent">
      <span class="left">{{ year }} 年假期余额</span>
      <span class="right">{{ percent }}%</span>
    </div>
    <div class="number">
      <span class="left">剩余假期天数（包含周末）</span>
      <span class="right">{{ totalVacation - countPassed }} / {{ totalVacation }} 天</span>
    </div>
  </div>
  <div class="dots">
    <slot v-for="(item, index) in dotArr">
      <div v-if="countPassed > index" class="dot expired" :key="'d' + index" />
      <div v-else-if="totalVacation <= index" class="dot invisible" :key="index" />
      <div v-else class="dot upcoming" :style="dotStyle[index]" :key="index" />
    </slot>
  </div>

</div>

</template>

<script>
import dayjs from 'dayjs'
import store from '@/store'
import utils from '@/utils'

const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

export default {
  name: 'balance',
  data () {
    return {
      countPassed: null,
      dotArr: null,
      percent: null,
      totalVacation: null,
      dotStyle: []
    }
  },
  computed: {
    now () {
      return store.state.now
    },
    year () {
      return store.state.year
    },
    vacation () {
      return store.state.vacation
    },
    nowFullDate () {
      return dayjs(store.state.now).format('YYYY.MM.DD') + ' ' + week[dayjs(store.state.now).day()]
    }
  },
  watch: {
    vacation (newVal) {
      this.calculate()
    }
  },
  methods: {
    calculate: function () {
      let year = this.year
      let now = this.now
      let result = utils.calculateVacation(this.vacation, year)
      let vacationThisYear = 0
      let occupiedThisYear = 0
      result.vacationList.forEach(function (item) {
        if (dayjs(item).year() === year) {
          vacationThisYear++
        }
      })
      result.occupiedList.forEach(function (item) {
        if (dayjs(item).year() === year) {
          occupiedThisYear++
        }
      })

      let weekendsThisYear = utils.countWeekends(year)
      let duplicatedThisYear = utils.countDuplicated(year, result.vacationList)
      this.countPassed = utils.countPassed(now, year, result.vacationList, result.occupiedList)
      this.totalVacation = vacationThisYear + weekendsThisYear - duplicatedThisYear - occupiedThisYear
      this.percent = Math.round((1 - this.countPassed / this.totalVacation) * 100)

      this.dotArr = Array(this.totalVacation + 14).fill() // 14 more for layout
      let dotStyle = []
      this.dotArr.forEach(function (item, index) {
        //  background: linear-gradient(to bottom, rgb(22,90,71), rgb(10,59,102))
        let lines = 14
        let line = Math.floor(index / 9)
        let rstep = Math.round((22 - 10) / lines)
        let gstep = Math.round((90 - 59) / lines)
        let bstep = Math.round((102 - 71) / lines)
        let str = 'background: linear-gradient(to bottom, rgb(' + (22 - rstep * line) + ', ' + (90 - gstep * line) + ',  ' + (71 + bstep * line) + '), rgb(' + (22 - rstep * (line + 1)) + ', ' + (90 - gstep * (line + 1)) + ', ' + (71 + bstep * (line + 1)) + '))'
        dotStyle.push(str)
      })
      this.dotStyle = dotStyle
    }
  },
  onShareAppMessage: function () {
    return {
      title: this.year + ' 年假期安排、请假攻略、假期余额',
      desc: '下一个假期',
      path: '/pages/index/main'
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.calculate()
  }
}
</script>

<style>
.today {
  text-align: center;
  font-size: 14px;
  line-height: 42px;
  font-weight: 100;
  color: #ddd;
}

.stats {
  border-top: 1px solid rgba(216,216,216,0.1);
  border-bottom: 1px solid rgba(216,216,216,0.1);
  padding: 18px;
  overflow: hidden;
}
.stats .left {
  float: left;
}
.stats .right {
  float: right;
}
.stats .percent {
  font-size: 18px;
  line-height: 25px;
}
.stats .number {
  clear: both;
  margin-top: 12px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 100;
  color: #ddd;
}

.dots {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 22px 8px;
}
.dot {
  width: 30px;
  height: 30px;
  margin: 12px;
  border-radius: 50%;
  order: 1;
}
.dot.expired {
  background-color: #4b4b4b;
}
.dot.upcoming {
  background: #a7c440;
}
.dot.invisible {
  background-color: #040D14;
}
@media only screen and (max-width: 600px) {
  .dot {
    width: 24px;
    height: 24px;
    margin: 8px;
  }
}
</style>
