<template>
<div class="index container { 'in-miniapp': isMiniapp, 'in-itab': isiTab, 'in-ios': isiOS }">
  <div class="scroll-inner">
    <div class="item" :id="item.id" v-for="(item, index) in vacation" :key="item.year + item.id">
      <div class="next-year" v-if="index === 0 || vacation[index-1].year !== item.year">{{ item.year }} 年放假安排</div>
      <router-link v-if="item.status === 'expired'" class="card small expired" :to="!isMiniapp ? '/calendar#' + item.month : '#'" :title="item.year + ' 年' + item.name + '放假安排'">
        <div class="detail">
          <img class="name-svg" :src="item.resource" />
          <span class="range">{{ item.startDate }} - {{ item.endDate }}</span>
          <div class="line"></div>
          <span class="length">{{ item.duration }} 天</span>
        </div>
        <div class="from-now">
          <p class="emoji">{{ item.emoji }}</p>
        </div>
      </router-link>
      <router-link v-else-if="item.status === 'onvacation'" class="card big" :to="!isMiniapp ? '/calendar#' + item.month : '#'" :title="item.year + ' 年' + item.name + '放假安排'">
        <div class="detail">
          <img class="name-svg" :src="item.resource" />
          <div class="date-range">
            <span class="range">{{ item.startDate }} - {{ item.endDate }}</span>
            <div class="line"></div>
            <span class="length">{{ item.duration }} 天</span>
          </div>
        </div>
        <div class="wishes">
          <div class="emoji">{{ item.emoji }}</div>
          <p>* 假期快乐 *</p>
        </div>
      </router-link>
      <router-link v-else-if="item.end" class="card small" :to="!isMiniapp ? '/calendar#' + item.month : '#'" :title="item.year + ' 年' + item.name + '放假安排'">
        <div class="detail">
          <img class="name-svg" :src="item.resource" />

          <div class="range">
            {{ item.startDate }} <span v-if="item.end">- {{ item.endDate }}</span>
          </div>
          <div class="line"></div>
          <span class="length" v-if="item.end">{{ item.duration }} 天</span>
        </div>
        <div class="from-now">
          <p class="number">{{ item.counter }}</p>
          <p class="day">{{ item.unit }}</p>
        </div>
      </router-link>
      <div v-else class="card small" @click="togglePopup">
        <div class="detail">
          <img class="name-svg" :src="item.resource" />

          <div class="range">{{ item.startDate || item.holidayDate }} <span v-if="item.end">- {{ item.endDate }}</span></div>
          <div class="line"></div>
          <span class="length" v-if="item.end">{{ item.duration }} 天</span>
          <span class="length" v-else>点击订阅放假安排</span>
        </div>
        <div class="from-now">
          <p class="number">{{ item.counter }}</p>
          <p class="day">{{ item.unit }}</p>
        </div>
      </div>
    </div>
    <div class="channel-button" @click="togglePopup">订阅 {{ year + 1 }} 年放假安排 🎉</div>
  </div>
  <div class="newsroom">
  </div>
  <a target="_blank" class="link wechat-miniapp" href="https://madebylesign.com/" v-if="!isMiniapp">下一个假期微信小程序版</a>
  <a target="_blank" class="link lesign" href="https://madebylesign.com/" v-if="!isMiniapp">Made by Lesign</a>
  <span class="link" v-if="isMiniapp && !isiOS">欢迎关注公众号：正在工作室</span>
  <span class="link lesign" v-if="isMiniapp">Made by Lesign</span>
  <span class="link icp" v-if="!isMiniapp && !isiTab && !isiOS"><a href="http://beian.miit.gov.cn/" target="_blank"> 京ICP备17007583号-5</a></span>

  <div class="popup" v-if="showPopup">
    <div class="popup-close" @click="togglePopup">
      <img src="../assets/circle-outline-delete.svg" mode="widthFix" />
    </div>
    <div class="popup-inner" @click="togglePopup">
      <div class="title">订阅假期安排</div>
      <div class="description">{{ year + 1 }} 年法定假期安排将于 {{ year }} 年末公布，「正在工作室」公众号会在第一时间进行推送。</div>
      <div class="description">微信扫描下方二维码即可关注。</div>
      <img class="channel-qr" src="https://general.lesignstatic.com/wechat/lesign-channel-qr.jpg" alt="关注正在工作室公众号">
      <div class="control">
        <button class="confirm">确定</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import dayjs from 'dayjs'
import store from '@/store'
import utils from '@/utils'

export default {
  name: 'index',
  data () {
    return {
      showPopup: false
    }
  },
  computed: {
    year () {
      return store.state.year
    },
    now () {
      return store.state.now
    },
    isMiniapp () {
      return store.state.isMiniapp
    },
    isiTab () {
      return store.state.isiTab
    },
    isiOS () {
      return store.state.isiOS
    },
    vacation () {
      let now = store.state.now
      let year = store.state.year
      let result = []
      if (store.state.vacation) {
        store.state.vacation.forEach(function (item) {
          item.id = item.enName.toLowerCase().split(' ').join('-')
          item.month = dayjs(item.end).month()
          item.resource = 'https://general.lesignstatic.com/jiaqi/static/' + item.enName.toLowerCase().split(' ').join('_') + '.svg'
          if (item.start) {
            item.startDate = dayjs(item.start).format('M.D')
          }
          if (item.end) {
            item.endDate = dayjs(item.end).format('M.D')
          }

          // 需要包含 end 那天
          let startTimestamp = dayjs(item.start).valueOf()
          let endTimestamp = dayjs(item.end).add(1, 'day').valueOf() - 1
          item.duration = Math.round((endTimestamp - startTimestamp) / ((24 * 3600 * 1000)))
          if (endTimestamp < now) {
            item.status = 'expired'
            item.emoji = utils.generateEmoji('sad')
          } else if (startTimestamp > now && year === item.year) {
            item.status = 'upcoming'
            item.unit = '天'
            item.counter = Math.ceil((startTimestamp - now) / (24 * 3600 * 1000))
            if ((startTimestamp - now) < (24 * 3600 * 1000)) {
              item.unit = '小时'
              item.counter = Math.floor((startTimestamp - now) / (3600 * 1000))
            }
          } else if (startTimestamp < now && endTimestamp > now) {
            item.status = 'onvacation'
            item.emoji = utils.generateEmoji('cheer')
          } else {
            item.holidayDate = dayjs(item.holiday).format('M.D')
            item.status = 'unknown'
            item.counter = Math.ceil((dayjs(item.holiday).valueOf() - now) / (24 * 3600 * 1000))
            item.unit = '天'
          }
          result.push(item)
        })
      }
      return result
    }
  },
  methods: {
    togglePopup: function () {
      // temp
      if (this.isMiniapp && !this.isiOS) return
      var newStatus = !this.showPopup
      this.showPopup = newStatus
    },
    setMonth: function (e) {
      let month = e.mp.currentTarget.dataset.month
      store.commit('setMonth', month)
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style>
.index {
  padding-bottom: 40px;
}
.item {
  margin: 20px 16px;
  box-sizing: border-box;
  overflow: hidden;
}
.item:hover .card {
  border: 3px solid ghostwhite;
  border-radius: 6px;
}
.item .card {
  display: block;
  box-sizing: border-box;
  border-radius: 4px;
  border: 3px solid transparent;
  color: #040D14;
  background-color: #eee;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  height: 100px;
  padding: 21px 25px;
  position: relative;
  transition: all .1s ease-in-out;
}
.item:nth-child(7n + 1) .card {
  background-color: #6BD1D6;
}
.item:nth-child(7n + 2) .card {
  background-color: #FFCA32;
}
.item:nth-child(7n + 3) .card {
  background-color: #1AFFBF;
}
.item:nth-child(7n + 4) .card {
  background-color: #4FC3D7;
}
.item:nth-child(7n + 5) .card {
  background-color: #AC8CD0;
}
.item:nth-child(7n + 6) .card {
  background-color: #FE9ABC;
}
.item:nth-child(7n + 7) .card {
  background-color: #FF7A58;
}
.item .card.expired {
  background-color: #8f8f8f;
}

.item .detail {
  box-sizing: border-box;
  width: 64%;
  float: left;
  overflow: hidden;
}
.item .detail .name {
  box-sizing: border-box;
  font-family: 'Noto Serif', 'Noto Serif CJK SC', 'Noto Serif CJK', 'Source Han Serif SC', 'Source Han Serif CN', 'Source Han Serif', 'source-han-serif-sc', serif;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 6px;
  line-height: 36px;
  float: left;
}
.item .detail .name-svg {
  height: 38px;
  width: 74px;
  margin-bottom: 6px;
  float: left;
}
.item:nth-child(1) .detail .name-svg, .item:nth-child(2) .detail .name-svg, .item:nth-child(8) .detail .name-svg, .item:nth-child(9) .detail .name-svg {
  height: 37px;
  width: 50px;
}
.item .detail .range {
  clear: both;
  font-size: 13px;
  float: left;
  line-height: 14px;
  font-family: Roboto, sans-serif;
}
.item .detail .length {
  font-size: 12px;
  float: left;
  line-height: 14px;
  font-family: Roboto, sans-serif;
}
.item .detail .line {
  height: 10px;
  width: 1px;
  background-color: #040D14;
  float: left;
  margin: 2px 6px;
}
.item .from-now {
  box-sizing: border-box;
  float: left;
  overflow: hidden;
  width: 36%;
  height: 100%;
  position: relative;
}
.item .from-now .number {
  font-size: 36px;
  line-height: 60px;
  position: absolute;
  right: 18px;
  bottom: 0;
  margin: 0;
  color: #040D14;
  font-family: "gilroyextrabold", Rubik, Roboto, sans-serif;
  font-weight: 600;
}
.item .from-now .emoji {
  font-size: 48px;
  float: right;
  opacity: 0.7;
  margin: 0;
}
.item .from-now .day {
  font-size: 12px;
  position: absolute;
  left: calc(100% - 12px);
  bottom: 6px;
  margin: 0;
  line-height: 20px;
  white-space: nowrap;
  color: #040D14;
}

.item .card.big {
  height: 240px;
  padding: 25px;
}
.item .card.big .detail {
  width: 100%;
}
.item .card.big .detail .date-range {
  float: right;
  margin-top: 8px;
}
.item .card.big .wishes {
  text-align: center;
  font-size: 16px;
}
.item .card.big .wishes .emoji {
  font-size: 60px;
  margin: 50px 0 30px;
}

.next-year {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  line-height: 60px;
}

.channel-button {
  background: #1AFFBF;
  display: block;
  border-radius: 2px;
  line-height: 56px;
  color: #222;
  text-align: center;
  margin: 16px 16px;
  padding: 0;
  font-size: 14px;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}
.newsroom {
  line-height: 24px;
  color: #999;
  margin: 30px auto;
}
.newsroom p {
  margin: 0;
  font-size: 14px;
}

.link {
  display: block;
  height: 32px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  margin: 12px auto;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  position: relative;
  display: block;
  text-align: center;
}
.link:hover {
  color: #4FC3D7;
}
</style>
