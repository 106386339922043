<template>
  <div id="app" :class="{ 'in-miniapp': isMiniapp && !isiOS, 'in-itab': isiTab, 'in-ios': isiOS }">
    <div class="app-header">
      <div class="logo" title="下一个假期">
        <img src="https://general.lesignstatic.com/logo/jiaqi-400.png" alt="下一个假期">
      </div>
      <div class="nav">
        <router-link :class="{selected: $route.name === 'balance'}" to="/balance" title="假期余额">假期余额</router-link>
        <router-link :class="{selected: $route.name === 'home'}" to="/" title="法定节假日放假安排">下一个假期</router-link>
        <router-link :class="{selected: $route.name === 'calendar'}" to="/calendar" title="法定节假日请假攻略">请假攻略</router-link>
      </div>
    </div>
    <router-view class="content-view" />
    <div class="miniapp-qr"><img src="https://general.lesignstatic.com/wechat/lesign-channel-qr.jpg" alt="正在工作室"></div>
  </div>
</template>
<script>
import store from '@/store'

export default {
  data () {
    return {
    }
  },
  computed: {
    isMiniapp () {
      return store.state.isMiniapp && !store.state.isiOS
    },
    isiTab () {
      return store.state.isiTab
    },
    isiOS () {
      return store.state.isiOS
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.$http.get('https://s3.cn-north-1.amazonaws.com.cn/general.lesignstatic.com/config/jiaqi.json').then(res => {
        store.commit('setConfig', res.data)
      })
      let data = {
        'year': 2020,
        'vacation': [
          {
            'holiday': '2020-01-01',
            'enName': 'New Year',
            'name': '元旦',
            'year': 2020,
            'start': '2020-01-01',
            'end': '2020-01-01',
            'occupied': []
          },
          {
            'holiday': '2020-01-25',
            'enName': 'Spring Festival',
            'name': '春节',
            'year': 2020,
            'start': '2020-01-24',
            'end': '2020-01-30',
            'occupied': [
              '2020-01-19',
              '2020-02-01'
            ]
          },
          {
            'holiday': '2020-04-04',
            'enName': 'Qingming Festival',
            'name': '清明节',
            'year': 2020,
            'start': '2020-04-04',
            'end': '2020-04-06'
          },
          {
            'holiday': '2020-05-01',
            'enName': 'Labour Day',
            'name': '劳动节',
            'year': 2020,
            'start': '2020-05-01',
            'end': '2020-05-05',
            'occupied': [
              '2020-04-26',
              '2020-05-09'
            ]
          },
          {
            'holiday': '2020-06-25',
            'enName': 'Dragon Boat Festival',
            'name': '端午节',
            'year': 2020,
            'start': '2020-06-25',
            'end': '2020-06-27',
            'occupied': [
              '2020-06-28'
            ]
          },
          {
            'holiday': '2020-10-01',
            'enName': 'Mid-Autumn Festival',
            'name': '中秋节',
            'year': 2020,
            'start': '2020-10-01',
            'end': '2020-01-08',
            'occupied': []
          },
          {
            'holiday': '2020-10-01',
            'enName': 'National Day',
            'name': '国庆节',
            'year': 2020,
            'start': '2020-10-01',
            'end': '2020-10-08',
            'occupied': [
              '2020-09-27',
              '2020-10-10'
            ]
          },
          {
            'holiday': '2021-01-01',
            'enName': 'New Year',
            'name': '元旦',
            'year': 2021
          },
          {
            'holiday': '2021-02-12',
            'enName': 'Spring Festival',
            'name': '春节',
            'year': 2021
          }
        ],
        'plans': {
          '0': {
            'names': [
              '请 2 休 10'
            ],
            'days': [
              [
                '2020-01-31',
                '2020-02-01'
              ]
            ]
          },
          '1': {
            'emoji': '🙄'
          },
          '2': {
            'emoji': '😱'
          },
          '3': {
            'emoji': '🤬'
          },
          '4': {
            'names': [
              '请 4 休 10'
            ],
            'days': [
              [
                '2020-05-06',
                '2020-05-07',
                '2020-05-08',
                '2020-05-09'
              ]
            ]
          },
          '5': {
            'names': [
              '请 4 休 9'
            ],
            'days': [
              [
                '2020-06-22',
                '2020-06-23',
                '2020-06-24',
                '2020-06-28'
              ]
            ]
          },
          '6': {
            'emoji': '💩'
          },
          '7': {
            'emoji': '😡'
          },
          '8': {
            'emoji': '🙄'
          },
          '9': {
            'names': [
              '请 4 休 13'
            ],
            'days': [
              [
                '2020-09-27',
                '2020-09-28',
                '2020-09-29',
                '2020-09-30'
              ]
            ]
          },
          '10': {
            'emoji': '😂'
          },
          '11': {
            'emoji': '😤'
          }
        },
        'version': 1574332210821
      }
      store.commit('setConfig', data)
    }
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}
body {
  background-color: #040D14;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
@font-face {
  font-family: "gilroyextrabold";
  src: url("https://general.lesignstatic.com/font/gilroy-extrabold-webfont.ttf");
}
.container {
  height: 100%;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  color: #fff;
  overflow: hidden;
  box-sizing: border-box;
}
.container .page-title {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  line-height: 60px;
}
#app {
}
.app-header {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: 600px;
  transform: translate(-50%, 0);
  background-color: #040D14;
  z-index: 9;
}
.logo {
  font-size: 32px;
  text-align: center;
  border-radius: 50%;
  border: 3px solid #fff;
  overflow: hidden;
  width: 80px;
  height: 80px;
  display: block;
  margin: 20px auto 20px;
}
.logo img {
  display: block;
  width: 100%;
  height: 100%;
}
.nav {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.nav a {
  float: left;
  width: calc(100% / 3);
  color: #fff;
  text-align: center;
  font-size: 20px;
  border-bottom: 3px solid transparent;
  line-height: 40px;
  text-decoration: none;
}
.nav a:hover {
  border-bottom: 3px solid #999;
}
.nav a.selected {
  border-bottom: 3px solid #dc4051;
}
.content-view {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 160px;
}
.miniapp-qr {
  width: 200px;
  height: 200px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 9;
}
.miniapp-qr img {
  display: block;
  width: 100%;
  height: 100%;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup .popup-close {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.popup .popup-close image {
  display: block;
  width: 100%;
  height: 100%;
}
.popup .popup-inner {
  width: 80%;
  max-width: 500px;
  overflow: hidden;
  color: #333;
  background: #fff;
  box-sizing: border-box;
  border-radius: 4px;
}
.popup .popup-inner .title {
  font-size: 18px;
  text-align: center;
  line-height: 36px;
  margin-top: 16px;
  color: #333;
}
.popup .popup-inner .description {
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  color: #888;
  margin: 12px 0;
  padding: 0 24px;
}
.popup .popup-inner .channel-qr {
  display: block;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}
.popup .popup-inner .control .confirm {
  background-color: #09BB07;
  color: #fff;
  border: none;
  border-radius: 0;
  margin-top: 20px;
  width: 100%;
  line-height: 40px;
  display: block;
}
.popup .popup-inner button::after {
  border-radius: 0;
  border: none;
  outline: none;
}

@media only screen and (max-width: 600px) {
  .miniapp-qr {
    position: relative;
    bottom: initial;
    right: initial;
    margin: 0 auto 20px;
  }
}

/* 小程序 Web View */
.in-miniapp .app-header, .in-miniapp .wechat-miniapp, .in-miniapp .newsroom {
  display: none;
}
.in-miniapp .content-view {
  margin-top: 0;
}
.in-miniapp .popup .popup-inner .channel-qr {
  width: 120px;
  height: 120px;
}
.in-miniapp .lesign, .in-miniapp .lesign:hover, .in-miniapp .lesign:active {
  text-decoration: none;
  color: #fff;
}
/* temp */
.in-miniapp .channel-button {
  display: none;
}
.in-miniapp .item .detail .line, .in-miniapp .item .detail .length {
  display: none;
}
.in-miniapp .miniapp-qr {
  display: none;
}

/* iTab */
.in-itab .logo, .in-itab .wechat-miniapp, .in-itab .newsroom {
  display: none;
}
.in-itab .content-view {
  margin-top: 30px;
}
.in-itab .popup .popup-inner .channel-qr {
  width: 120px;
  height: 120px;
}
.in-itab .lesign, .in-itab .lesign:hover, .in-itab .lesign:active {
  text-decoration: none;
  color: #fff;
}
/* temp */
.in-itab .channel-button {
  position: relative;
}
.in-itab .item .detail .line, .in-itab .item .detail .length {
  display: none;
}
.in-itab .miniapp-qr {
  display: none;
}

/* iOS App */
.in-ios .miniapp-qr {
  display: none;
}

</style>
