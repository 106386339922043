import dayjs from 'dayjs'

function calculateVacation (vacation, year) {
  var result = {
    holidayList: [],
    vacationList: [],
    occupiedList: []
  }
  for (let i in vacation) {
    let obj = vacation[i]
    let oneDay = 24 * 3600 * 1000
    if (!obj.start) continue
    let j = 0
    let holiday = dayjs(vacation[i].holiday).format('YYYY-MM-DD')
    result.holidayList.push(holiday)
    let startTimestamp = dayjs(obj.start).valueOf()
    let endTimestamp = dayjs(obj.end).add(1, 'day').valueOf()
    while (startTimestamp + j * oneDay < endTimestamp) {
      let loopDay = startTimestamp + j * oneDay
      result.vacationList.push(dayjs(loopDay).format('YYYY-MM-DD'))
      j++
    }

    if (obj.occupied) {
      for (let k in obj.occupied) {
        let tmp = dayjs(obj.occupied[k]).format('YYYY-MM-DD')
        result.occupiedList.push(tmp)
      }
    }
  }

  return result
}
function countWeekends (year, offPerWeek) {
  offPerWeek = offPerWeek || 2
  // let firstDay = new Date(year, 0, 1)
  let lastDay = new Date(year + 1, 0, 0).getDay()
  let beforeLastDay = new Date(year + 1, 0, -1).getDay()
  let isLeapYear = ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
  let result = 52 * offPerWeek

  if (isLeapYear) {
    result += ((lastDay === 6 || lastDay === 0) ? 1 : 0) + (beforeLastDay === 6 || beforeLastDay === 0) ? 1 : 0
  } else {
    result += (lastDay === 6 || lastDay === 0) ? 1 : 0
  }
  return result
}

function countDuplicated (year, vacationList) {
  let result = 0
  for (let i in vacationList) {
    let date = vacationList[i]
    if (dayjs(date).year() === year && (dayjs(date).day() === 6 || dayjs(date).day() === 0)) {
      result++
    }
  }
  return result
}
function countPassed (timestamp, year, vacationList, occupiedList) {
  timestamp = new Date(timestamp).getTime()
  let result = 0
  let firstDayTimestamp = dayjs(`${year}-01-01 00:00:00`).valueOf()
  for (let i = 0; firstDayTimestamp + i * 24 * 3600 * 1000 < timestamp; i++) {
    let thisDay = dayjs(firstDayTimestamp + i * 24 * 3600 * 1000).format('YYYY-MM-DD')
    let week = dayjs(thisDay).day()
    if ((week === 6 || week === 0 || vacationList.indexOf(thisDay) >= 0) && occupiedList.indexOf(thisDay) < 0) {
      result++
    }
  }
  return result
}

function generateEmoji (key) {
  var obj = {
    sad: ['😂', '🙈', '😱', '😤', '💊', '🤔', '💩', '🙀', '🤬', '😡'],
    cheer: ['🎉', '🎊', '🎈', '😎', '🤩']
  }
  var random = Math.random()
  return obj[key][Math.floor(random * obj[key].length)]
}

function generateDays (year, vacation, fill) {
  let vacationResult = calculateVacation(vacation, year)
  let result = []
  let number = 42
  for (var month = 0; month < 12; month++) {
    let shift = (new Date(year, month, 1).getDay() + 6) % 7 || 7
    if (year === 2019 && month === 0) {
      shift = 8
    }
    if (year === 2019 && month === 4) {
      shift = 9
    }
    let thisMonth = Array(number).fill().map(function (v, i) {
      let day = {}
      let timestamp = new Date(year, month, i + 1 - shift).getTime()
      let ymd = dayjs(timestamp).format('YYYY-MM-DD')
      day.month = dayjs(timestamp).month()
      day.id = timestamp
      day.date = dayjs(timestamp).date()
      day.ymd = ymd
      if (vacationResult.holidayList.indexOf(ymd) >= 0) {
        day.special = 'holiday'
      } else if (vacationResult.vacationList.indexOf(ymd) >= 0) {
        day.special = 'vacation'
      } else if (vacationResult.occupiedList.indexOf(ymd) >= 0) {
        day.special = 'occupied'
      } else if (dayjs(timestamp).day() === 6 || dayjs(timestamp).day() === 0) {
        day.special = 'weekend'
      }
      return day
    })
    result.push(thisMonth)
  }
  return result
}

export default {
  calculateVacation,
  countWeekends,
  countDuplicated,
  countPassed,
  generateEmoji,
  generateDays
}
