import Vue from 'vue'
import Router from 'vue-router'
import Index from './views/Index.vue'
import Balance from './views/Balance.vue'
import Calendar from './views/Calendar.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Index
    },
    {
      path: '/balance',
      name: 'balance',
      component: Balance
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Calendar
    }
  ]
})
