<template>
<div class="container calendar-container" v-if="plans">
  <h2 class="page-title">{{ year }} 年请假攻略</h2>
  <div v-for="month in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]" :id="month" :key="month" class="month">
    <div class="month-title">
      <span>{{ chineseNumber[month] }}月</span> <span class="year">{{ year }}</span>
    </div>
    <div class="guide">
      <span>请假攻略</span>
      <slot v-if="plans[month] && plans[month].names">
        <slot v-for="(name, planIndex) in plans[month].names">
          <button class="plan" :class="{ 'selected': month === selectedPlanMonth && planIndex === selectedPlanIndex }" @click="selectPlan(month, planIndex)" :key="month + planIndex" hover-class="plan-hover">{{ name }}</button>
        </slot>
      </slot>
      <span v-else-if="plans[month]" class="sorry">{{ plans[month].emoji }}</span>
    </div>
    <div class="calendar">
      <div class="item header">一</div>
      <div class="item header">二</div>
      <div class="item header">三</div>
      <div class="item header">四</div>
      <div class="item header">五</div>
      <div class="item header">六</div>
      <div class="item header">日</div>
      <slot v-for="day in days[month]">
        <div v-if="day.special === 'holiday'" class="item day holiday" :key="day.id">
          <span class="date" :class="{inactive: day.month < month || day.month > month}">{{ day.date }}</span>
          <span class="sub">节</span>
        </div>
        <div v-else-if="day.special === 'vacation'" class="item day vacation" :key="day.id">
          <span class="date" :class="{inactive: day.month < month || day.month > month}">{{ day.date }}</span>
          <span class="sub">假</span>
        </div>
        <div v-else-if="day.isInPlan" class="item day off" :key="day.id">
          <span class="date" :class="{inactive: day.month < month || day.month > month}">{{ day.date }}</span>
          <span class="sub">请</span>
        </div>
        <div v-else-if="day.special === 'occupied'" class="item day occupied" :key="day.id">
          <span class="date" :class="{inactive: day.month < month || day.month > month}">{{ day.date }}</span>
          <span class="sub">班</span>
        </div>
        <div v-else-if="day.special === 'weekend'" class="item day weekend" :key="day.id">
          <span class="date" :class="{inactive: day.month < month || day.month > month}">{{ day.date }}</span>
          <span class="sub">休</span>
        </div>
        <div v-else class="item day workday" :key="day.id">
          <span class="date" :class="{inactive: day.month < month || day.month > month}">{{ day.date }}</span>
        </div>
      </slot>
    </div>
  </div>
</div>
</template>

<script>
import utils from '@/utils'
import store from '@/store'

export default {
  name: 'calendar',
  data () {
    return {
      chineseNumber: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'],
      selectedPlanMonth: null,
      selectedPlanIndex: null,
      days: []
    }
  },
  computed: {
    year () {
      return store.state.year
    },
    plans () {
      return store.state.plans
    },
    viewMonth () {
      return store.state.month !== null ? 'm' + store.state.month : 'm0'
    }
  },
  watch: {
    plans (newVal) {
      this.calculate()
    }
  },
  methods: {
    calculate () {
      let vacation = store.state.vacation
      let year = this.year
      this.days = utils.generateDays(year, vacation, true)
    },
    resetInPlanDays (month) {
      if (typeof month !== 'number') return
      let injectMonth = this.days[month]
      injectMonth.forEach(function (day, index) {
        delete injectMonth[index].isInPlan
      })
      this.days[month] = injectMonth
    },
    selectPlan (month, index) {
      this.resetInPlanDays(this.selectedPlanMonth)
      store.commit('setMonth', month)
      if (month !== this.selectedPlanMonth || index !== this.selectedPlanIndex) {
        this.selectedPlanMonth = month
        this.selectedPlanIndex = index
        let selectedPlan = this.plans[month].days[index]
        let injectMonth = this.days[month]
        injectMonth.forEach(function (day, index) {
          if (selectedPlan.indexOf(day.ymd) >= 0) {
            injectMonth[index].isInPlan = true
          }
        })
        this.days[month] = injectMonth
      } else {
        this.selectedPlanMonth = null
        this.selectedPlanIndex = null
      }
    }
  },
  onShareAppMessage: function () {
    return {
      title: this.year + ' 年假期安排、请假攻略、假期余额',
      desc: '下一个假期',
      path: '/pages/index/main'
    }
  },
  onShow () {
  },
  mounted () {
    this.calculate()
  }
}
</script>

<style>
.calendar-container {
  overflow: hidden;
}
.month {
  margin: 0 0 30px;
  padding: 12px;
  box-sizing: border-box;
}
.month-title {
  font-size: 24px;
  font-weight: 500;
  padding: 0 8px;
}
.month-title .year {
  font-weight: 100;
  font-family: "Helvetica Neue", sans-serif;
  margin-left: 12px;
}
.guide {
  line-height: 64px;
  color: #ffca32;
  font-size: 14px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  margin: 20px 0;
}
.guide .plan {
  line-height: 28px;
  border: 1px solid #fff;
  border-radius: 14px;
  padding: 0 12px;
  font-size: 12px;
  background-color: #040D14;
  color: #fff;
  float: right;
  margin: 16px 0 16px 8px;
  outline: none;
}
.guide .plan-hover, .guide .plan.selected {
  color: #ffca32;
  border: 1px solid #ffca32;
}
.guide .sorry {
  float: right;
  font-size: 24px;
  opacity: .7;
}
.calendar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.calendar .item {
  width: calc(100%/7);
  line-height: 48px;
  margin: 12px 0;
  color: #999DA0;
  box-sizing: border-box;
  text-align: center;
}
.calendar .item:hover {
  background-color: #333;
}
.calendar .item.header {
  font-size: 14px;
  font-weight: 400;
  color: #296775;
}
.calendar .item.day {
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Helvetica Neue", Menlo, sans-serif;
  position: relative;
}

.calendar .item.day .date-only {
  line-height: 48px;
  span-align: center;
}
.calendar .item.day .date {
  font-size: 14px;
  line-height: 18px;
  span-align: center;
}

.calendar .item.day:not(.workday)::before {
  content: ' ';
  display: block;
  width: 36px;
  height: 1px;
  background: #999;
  position: absolute;
  left: calc(50% - 6px);
  top: 30px;
  transform: rotate(130deg);
}
.calendar .item.day .sub {
  font-size: 10px;
  line-height: 24px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.calendar .day .date.inactive {
  color: #555;
}
.calendar .day.weekend .sub {
  color: #1AFFBF;
}
.calendar .day.occupied .sub {
  color: #FE9ABC;
}
.calendar .day.vacation .sub {
  color: #1AFFBF;
}
.calendar .day.holiday .sub {
  color: #FF7A58;
}
.calendar .day.off .sub {
  color: #FFCA32;
}

@media only screen and (max-width: 600px) {
  .calendar .item {
    margin: 6px auto;
  }
}
</style>
